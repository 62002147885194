// @mixin respond-to: responding to devices breakpoint, creating media query that wrap it's contents
// @params $breakpoint: device breakpoint name, defined in $breakpoints

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Nilai untuk breakpoint `#{$breakpoint}` tidak ditemukan. Breakpoint yang tersedia #{map-keys(breakpoints)}";
  }
}

// @mixin max-respond-to: responding to max of devices breakpoint, creating media query that wrap it's contents
// @params $breakpoint: device breakpoint name, defined in $breakpoints

@mixin max-respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (max-width: #{map-get($breakpoints, $breakpoint) - 1}) {
      @content;
    }
  }

  @else {
    @warn "Nilai untuk breakpoint `#{$breakpoint}` tidak ditemukan. Breakpoint yang tersedia #{map-keys(breakpoints)}";
  }
}

@mixin border-radius($key) {
  @if map-has-key($border-radiuses, $key) {
    border-radius: map-get($border-radiuses, $key) !important;
  }

  @else {
    @warn "Nilai untuk radius `#{$key}` tidak ditemukan. Radius yang tersedia #{map-keys($border-radiuses)}";
  }
}

@mixin border-top-radius($key) {
  @if map-has-key($border-radiuses, $key) {
    border-top-left-radius: map-get($border-radiuses, $key) !important;
    border-top-right-radius: map-get($border-radiuses, $key) !important;
  }

  @else {
    @warn "Nilai untuk radius `#{$key}` tidak ditemukan. Radius yang tersedia #{map-keys($border-radiuses)}";
  }
}

@mixin border-bottom-radius($key) {
  @if map-has-key($border-radiuses, $key) {
    border-bottom-left-radius: map-get($border-radiuses, $key) !important;
    border-bottom-right-radius: map-get($border-radiuses, $key) !important;
  }

  @else {
    @warn "Nilai untuk radius `#{$key}` tidak ditemukan. Radius yang tersedia #{map-keys($border-radiuses)}";
  }
}

@mixin border-left-radius($key) {
  @if map-has-key($border-radiuses, $key) {
    border-top-left-radius: map-get($border-radiuses, $key) !important;
    border-bottom-left-radius: map-get($border-radiuses, $key) !important;
  }

  @else {
    @warn "Nilai untuk radius `#{$key}` tidak ditemukan. Radius yang tersedia #{map-keys($border-radiuses)}";
  }
}

@mixin border-right-radius($key) {
  @if map-has-key($border-radiuses, $key) {
    border-top-right-radius: map-get($border-radiuses, $key) !important;
    border-bottom-right-radius: map-get($border-radiuses, $key) !important;
  }

  @else {
    @warn "Nilai untuk radius `#{$key}` tidak ditemukan. Radius yang tersedia #{map-keys($border-radiuses)}";
  }
}