@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import './__normalize';
@import './__utils';

html {
  width: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;

  &::before {
    @extend *;
  }

  &::after {
    @extend *;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, get-font-family('base');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: get-color('background');
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(21, 21, 21, 0.3);
    border-radius: 1000px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, get-font-family('heading');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar:hover {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#baleo-seller {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
}

.card {
  // background-color: white;
  // border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;
  // padding: 1rem;
  border-radius: 10px;
  box-shadow: #cad3e1 0px 1px 4px 0px;
  background-color: white;
  padding: 1rem;
}

.card-product {
  border-radius: 10px;
  box-shadow: #cad3e1 0px 1px 4px 0px;
  background-color: white;
  padding: 1rem;
}

.hide-on-mobile {
  @include max-respond-to('small') {
    display: none !important;
    visibility: none !important;
    opacity: 0 !important;
  }
}

.show-on-mobile-only {
  @include respond-to('medium') {
    display: none !important;
    visibility: none !important;
    opacity: 0 !important;
  }
}

$rounded-el: '.rounded';

@each $index, $value in $border-radiuses {
  #{$rounded-el}-#{$index} {
    @include border-radius($index);
  }

  #{$rounded-el}-top-#{$index} {
    @include border-top-radius($index);
  }

  #{$rounded-el}-bottom-#{$index} {
    @include border-bottom-radius($index);
  }

  #{$rounded-el}-left-#{$index} {
    @include border-left-radius($index);
  }

  #{$rounded-el}-right-#{$index} {
    @include border-right-radius($index);
  }
}
