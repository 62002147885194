#loader {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  #brand-spinner {
    display: block;
    width: 40%;
    margin: 35vh auto 0 auto;
    max-width: 210px;
  }
}
